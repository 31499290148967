import {FC, Fragment, useEffect, useMemo, useState} from "react";
import {BookmakerData, ExtraMarketObject} from "../../../@types/response";
import {useSelector} from "react-redux";
import {ENABLED_BOOKMAKERS} from "../../../constants/ExtraConstants";
import {AVERAGE} from "../../../constants/CommonConstants";
import {getBookmakers} from "../../../store/selectors/getBookmakers";

interface ExtraTableProps {
    odds: any
    markets: ExtraMarketObject[]
}

export const ExtraTable: FC<ExtraTableProps> = ({odds, markets}) => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);

    const [bestBookmakers, setBestBookmakers]: any = useState({});

    const extraBookmakers: BookmakerData = useMemo(() => {
        return Object.keys(bookmakers)
                .filter(id => ENABLED_BOOKMAKERS.has(parseInt(id)))
                .reduce((a, v) => ({...a, [v]: bookmakers[v]}), {})
    }, [bookmakers])

    const eventBookmakers: number[] = Object.values(extraBookmakers).map(b => b.id);

    useEffect(() => {
        if (!markets.length || !Object.keys(odds).length) {
            setBestBookmakers({})
            return
        }
        let bests: any = {}
        // for (const market of markets) {
        //     for (const bookmakerId in bookmakers) {
        //         if (bookmakers[bookmakerId].isComparison || bookmakers[bookmakerId].details < 0) continue
        //         if (!bests[market.id.toString()]) bests[market.id.toString()] = {}
        //         for (const sign of Object.keys(market.outcomes)) {
        //             const sbv = marketsSbvs[market.name]?.[extraId.toString()]?.[bookmakerId] ? marketsSbvs[market.name][extraId.toString()][bookmakerId] : '#'
        //             const backOdd = odds[bookmakerId]?.extras[extraId]?.['markets']?.[market.id]?.[sbv]?.[sign]?.odd || 0
        //             if (!bests[market.id.toString()][sbv]) bests[market.id.toString()][sbv] = {}
        //             if (!bests[market.id.toString()][sbv][sign]) {
        //                 bests[market.id.toString()][sbv][sign] = backOdd
        //             } else {
        //                 bests[market.id.toString()][sbv][sign] = backOdd > bests[market.id.toString()][sbv][sign] ? backOdd : bests[market.id.toString()][sbv][sign]
        //             }
        //         }
        //     }
        // }
        setBestBookmakers(bests)
    }, [markets, odds])

    return <table className='mt-4 border-spacing-0 w-full'>
        <thead>
        <tr>
            <th/>
            <th/>
            {markets.map(item => {
                return <Fragment>
                    <th title={item.name} colSpan={Object.keys(item.outcomes || []).length}>
                        <span style={{fontSize: 'smaller'}}>{item.name}</span> <br/>
                    </th>
                    <th></th>
                </Fragment>
            })}
        </tr>
        <tr>
            <th style={{width: '125px'}} className={'font-extralight text-sm text-center px-2 whitespace-nowrap'}/>
            <th style={{width: '5px'}} className='bg-eventDetail-primary'></th>
            {markets.map(item => {
                return <Fragment key={item.id}>
                    {Object.values(item.outcomes).map(sign => {
                        return <Fragment>
                            <th title={sign.name} style={{width: '50px'}}
                                className={'font-extralight text-sm text-center px-1 overflow-hidden'}>
                                {sign.name}
                            </th>
                        </Fragment>
                    })}
                    <th style={{width: '5px'}}/>
                </Fragment>
            })}
        </tr>
        </thead>
        <tbody>
        {eventBookmakers.map((order_id) => {
            if (order_id === undefined || !Object.keys(bookmakers).includes(order_id.toString())) return null
            const bookmaker = bookmakers[order_id]
            const bgBook = 'bg-eventDetail-bookmaker'
            return <tr key={`book-${bookmaker.id}`}
                       className={`${bookmaker.details > 0 ? 'border' : 'border-b'} border-monitor-primary ${bgBook}`}>
                <th style={{height: '32px', width: '125px'}}
                    className={`text-left sticky left-0 z-10 ${bgBook} border-eventDetail-primary`}>
                    {bookmaker.name}
                </th>
                <th style={{width: '5px'}} className='bg-eventDetail-primary'></th>
                {markets.map(item => {
                    return (
                            <Fragment key={item.id}>
                                {Object.keys(item.outcomes).map(sign => {
                                    try {
                                        const mm = Object.keys(odds[bookmaker.id]?.[item.id])
                                        if (mm.length !== 1) {
                                            new Error("Only one Main market is allowed here")
                                        }
                                        const main = mm[0]
                                        const backOdd = bookmaker.id === AVERAGE ? 0 : odds[bookmaker.id]?.[item.id.toString()]?.[main]?.[sign]?.odd;
                                        const top = AVERAGE in bestBookmakers ? 0 : 0
                                        return <td key={sign} style={{width: '50px'}}
                                                   className={`text-center px-1.5 ${!bookmaker.isComparison && backOdd >= top && 'outline outline-1 -outline-offset-2 outline-eventDetail-secondary bg-eventDetail-top'}`}
                                        >
                                            <b>
                                                {main !== '#' ? <Fragment>
                                                    <i className="font-thin text-table-sbv">[{main}]</i>&nbsp;
                                                </Fragment> : null}
                                                {backOdd > 1 ? backOdd : '-'}
                                            </b>
                                        </td>
                                    } catch (ex) {
                                        return <td style={{width: '50px'}}/>
                                    }
                                })}
                                <td style={{width: '5px'}} className='bg-eventDetail-primary'/>
                            </Fragment>
                    )
                            ;
                })}
            </tr>
        })}
        </tbody>
    </table>
}
